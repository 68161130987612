import React from "react";
import Layout from "../../components/layout";
import Modal from "../../components/Modal";
import Seo from "../../components/seo";
import * as styles from "./jobs.module.scss";
import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "react-portable-text";

import TextSemibold from "../../utils/text-semibold";
import TextMedium from "../../utils/text-medium";

export const query = graphql`
  query JobsQuery {
    allSanitySiteSettings {
      nodes {
        _rawJobs
      }
    }
  }
`;

const TeamPage = ({ data }) => {
  return (
    <Modal one>
      <Seo title="Jobs" />
      <div className={styles.Jobs}>
        <PortableText
          content={data.allSanitySiteSettings.nodes[0]._rawJobs}
          serializers={{
            bigtext: BigText,
            semibold: TextSemibold,
            medium: TextMedium,
          }}
        />
      </div>
    </Modal>
  );
};

const BigText = ({ children }) => <p className={styles.BigText}>{children}</p>;

TeamPage.Layout = Layout;

export default TeamPage;
